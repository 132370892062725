export default {
    path:'/reseller',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'reseller',
            component : () => import('@/views/reseller/index.vue')
        },
        {
            path:'add',
            name:'reseller.tambah',
            component : () => import('@/views/reseller/create.vue')
        },
        {
            path:'edit/:id',
            name:'reseller.ubah',
            component : () => import('@/views/reseller/edit.vue')
        },
        {
            path:'detail/:id',
            name:'reseller.detail',
            component : () => import('@/views/reseller/detail.vue')
        },
    ]
}

