export default {
    namespaced: true,
    state: {
      showDevice: [],
      currentDevice: '',
      reportDevice: '',
      customer:'',
    },
    mutations: {
      showDevice:(state,payload) => {
          let cek = state.showDevice.find(x => x.id === payload.id)
          if(!cek) {
              state.showDevice.push(payload)
          } else {
              let index =state.showDevice.findIndex(x => x.id === payload.id)
              state.showDevice.splice(index,1,cek)
          }
      },
      customer:(state,payload) => {
        state.customer = payload
      },
      currentDevice:(state,payload) => {
          state.currentDevice = payload
      },
      reportDevice:(state,payload) => {
        state.reportDevice = payload
      }
    },
  
    actions: {
      addDevice: ({ commit }, payload) => {
        commit('showDevice', payload)
      },
      setCurrentDevice:({commit},payload) => {
        commit('currentDevice',payload)
      },
      setCustomer:({commit},payload) => {
        commit('customer',payload)
      },
      setReportDevice:({commit},payload) => {
        commit('reportDevice',payload)
      }
    },
  
    getters: {
      showDevice: state => state.showDevice,
      customer: state => state.customer,
      currentDevice: state => state.currentDevice,
      reportDevice: state => state.reportDevice,
    }
  }
  