export default {
    path:'/group-sensor',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Group sensor',
            component : () => import('@/views/group_sensor/index.vue')
        },
        {
            path:'add',
            name:'Group sensor.tambah',
            component : () => import('@/views/group_sensor/create.vue')
        },
        {
            path:'edit/:id',
            name:'Group sensor.ubah',
            component : () => import('@/views/group_sensor/edit.vue')
        },
      
       
    ]
}

