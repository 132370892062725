export default {
    path:'/customer_device',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Perangkat Pelanggan',
            component : () => import('@/views/customer_device/index.vue')
        },
        
    ]
}

