export default {
    namespaced: true,
    state: {
      token: '',
      user: null,
      status:'',
      detail:'',
      menu:[],
      device:[],
    },
    mutations: {
      user: (state, payload) => {
        state.user = payload ? payload.user : ''
        state.menu = payload.menu ? payload.menu : state.menu
        if (payload.menu) {
          state.menu = payload.menu.map( x => {
            let menu = x 
            menu.hover = false
            menu.statusChild = false
            menu.child = menu.Menus.length > 0 ? menu.Menus.map(z =>  
              {let child = z 
              child.hover = false
              return child
              }
            ) : []
            return menu
          })
        }
        state.status = payload.status ? payload.status : ''
        state.token = payload.token ? payload.token : ''
        state.detail = payload.detail ? payload.detail : ''
      },
      device:(state,payload) => {
        state.device = payload
      }
    },
  
    actions: {
      setUser: ({ commit }, payload) => {
        commit('user', payload)
      },
      setDevice: ({ commit }, payload) => {
        commit('device', payload)
      },
    },
  
    getters: {
      token: state => state.token,
      user: state => state.user,
      device: state => state.device,
      status: state => state.status,
      detail: state => state.detail,
      menu: state => state.menu,
      role_id: (state) => state.user.role_id,
    }
  }
  