export default {
    path:'/customer',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'perangkat',
            component : () => import('@/views/customer/index.vue')
        },
        {
            path:'add',
            name:'perangkat.tambah',
            component : () => import('@/views/customer/create.vue')
        },
        {
            path:'edit/:id',
            name:'perangkat.ubah',
            component : () => import('@/views/customer/edit.vue')
        },
        {
            path:'detail/:id',
            name:'perangkat.detail',
            component : () => import('@/views/customer/detail.vue')
        },
    ]
}

