export default {
    path:'/publictoken',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'public token',
            component : () => import('@/views/publictoken/index.vue')
        },
        {
            path:'add',
            name:'public token.tambah',
            component : () => import('@/views/publictoken/create.vue')
        },
        {
            path:'edit/:id',
            name:'public token.ubah',
            component : () => import('@/views/publictoken/edit.vue')
        },
    ]
}

