export default {
    path:'/log-version',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Data Log Version',
            component : () => import('@/views/version/index.vue')
        },
        {
            path:'add',
            name:'Tambah Log Version',
            component : () => import('@/views/version/create.vue')
        },
        {
            path:'edit/:id',
            name:'Ubah Log Version',
            component : () => import('@/views/version/edit.vue')
        },
     
    ]
}

