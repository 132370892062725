import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import device from './device'
import data from './data'
import image from './image'
import sidebar from './sidebar'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebar: true,
    beforeUrl: '',
  },
  mutations: {
    setSidebar: (state, status) => {
      state.sidebar = status
    },
    setBeforeUrl: (state, url) => {
      state.beforeUrl = url
    },
  },
  actions: {
    setSidebar: ({ commit }, status) => {
      commit('setSidebar', status)
    },
    setBeforeUrl: ({ commit }, url) => {
      commit('setBeforeUrl', url)
    },
  },
  getters:{
    sidebar: state => state.sidebar,
    beforeUrl: state => state.beforeUrl,
  },
  modules: {
    auth,
    device,
    data,
    image,
    sidebar
  }
})
