export default {
    namespaced: true,
    state: {
        sidebar:true
    },
    mutations: {
      sidebar:(state,payload) => {
         state.sidebar = payload
      },
     
    },
  
    actions: {
      setSidebar: ({ commit }, payload) => {
        commit('sidebar', payload)
      },
    },
  
    getters: {
      sidebar: state => state.sidebar,
    }
  }
  