export default {
    path:'/device_alert',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Device.Peringatan',
            component : () => import('@/views/device_alert/index.vue')
        },
        
    ]
}

