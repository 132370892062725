export default {
    path:'/tiket',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'tiket',
            component : () => import('@/views/tiket/index.vue')
        },
        {
            path:'add',
            name:'tiket.tambah',
            component : () => import('@/views/tiket/create.vue')
        },
        {
            path:'edit/:id',
            name:'tiket.ubah',
            component : () => import('@/views/tiket/edit.vue')
        },
        {
            path:'detail/:id',
            name:'tiket.detail',
            component : () => import('@/views/tiket/detail.vue')
        },
    ]
}

