export default {
    namespaced: true,
    state: {
      data: [],
      currentPath:'',
      dialogChangePassword:false,
      walthrough:true
    },
    mutations: {
      data:(state,payload) => {
         state.data = payload
      },
      dialogChangePassword:(state,payload) => {
        state.dialogChangePassword = payload
      },
      currentPath:(state,payload) => {
        state.currentPath = payload
      },
      walthrough:(state,payload) => {
        state.walthrough = payload
      },
    },
    actions: {
      setData: ({ commit }, payload) => {
        commit('data', payload)
      },
      setCurrentPath: ({ commit }, payload) => {
        commit('currentPath', payload)
      },
      setWalthrough: ({ commit }, payload) => {
        commit('walthrough', payload)
      },
      setDialogChangePassword:({commit},payload)=>{
        commit('dialogChangePassword',payload)
      }
    },
    getters: {
      data: state => state.data,
      currentPath: state => state.currentPath,
      walthrough: state => state.walthrough,
      dialogChangePassword: state => state.dialogChangePassword,
    }
  }
  