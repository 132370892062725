export default {
    path:'/device-param',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Device Param',
            component : () => import('@/views/device_param/index.vue')
        },
        {
            path:'add',
            name:'Device Param.tambah',
            component : () => import('@/views/device_param/create.vue')
        },
        {
            path:'edit/:id',
            name:'Device Param.ubah',
            component : () => import('@/views/device_param/edit.vue')
        },
      
       
    ]
}

