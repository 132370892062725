export default {
    path:'/setting',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'setting',
            component : () => import('@/views/setting/index.vue')
        },
    ]
}

