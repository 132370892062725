export default {
    path:'/monitor',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Monitor Perangkat',
            component : () => import('@/views/monitor/index.vue')
        },
        // {
        //     path:'add',
        //     name:'Tambah Perangkat',
        //     component : () => import('@/views/monitor/create.vue')
        // },
        {
            path:'detail',
            name:'Monitor Perangkat',
            component : () => import('@/views/monitor/detail.vue')
        },
       
    ]
}

