export default {
    path:'/whatsapp',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'whatsapp',
            component : () => import('@/views/whatsapp/index.vue')
        },
        {
            path:'registered',
            name:'whatsapp.registered',
            component : () => import('@/views/whatsapp/registered.vue')
        },
        {
            path:'add',
            name:'whatsapp.tambah',
            component : () => import('@/views/whatsapp/create.vue')
        },
        {
            path:'edit/:id',
            name:'whatsapp.ubah',
            component : () => import('@/views/whatsapp/edit.vue')
        },
    ]
}

