import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
import prfileRouter from'./profile'
import settingRouter from'./setting'
import usersRouter from './users'
import customerRouter from './customer'
import customer_deviceRouter from './customer_device'
import resellerRouter from './reseller'
import menuRouter from './menu'
import deviceRouter from './device'
import monitorRouter from './monitor'
import reportRouter from './report'
import roleRouter from './role'
import group_sensorRouter from './group_sensor'
import category_deviceRouter from './category_device'
import device_paramRouter from './device_param'
import device_alertRouter from './device_alert'
import versionRouter from './version'
import whatsappRouter from './whatsapp'
import publictokenRouter from './publictoken'
import tiketRouter from './tiket'
Vue.use(VueRouter)

const routes = [
  {
    path: '/demo_request',
    name: 'demo_request',
    meta:{auth:false},
    component: () => import('@/views/demo_request.vue')
  },
  {
    path: '/',
    name: 'homepage',
    meta:{auth:false},
    component: () => import('@/views/home_page.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Auth/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Auth/forgot_password.vue')
  },
  {
    path: '/reset-password',
    name: 'reset',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Auth/reset_password.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '@/views/map.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta:{auth:true},
    component: () => import(/* webpackChunkName: "about" */ '@/views/Dashboard.vue')
  },
  {
    path: '/log',
    name: 'log',
    meta:{auth:true},
    component: () => import(/* webpackChunkName: "about" */ '@/views/logactivities/index.vue')
  },
  prfileRouter,
  usersRouter,
  customerRouter,
  resellerRouter,
  group_sensorRouter,
  settingRouter,
  deviceRouter,
  monitorRouter,
  reportRouter,
  menuRouter,
  category_deviceRouter,
  device_paramRouter,
  device_alertRouter,
  versionRouter,
  tiketRouter,

  roleRouter,
  publictokenRouter,
  customer_deviceRouter,
  whatsappRouter,
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import('@/views/errors/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  if (to.path != '/login') {
    store.dispatch('setBeforeUrl', to.path)
  }
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/user']) {
      next()
    } else {
      next('/login')
    }
  } else if (to.name === 'login') {
    if (store.getters['auth/user'] == null) {
      next()
    } else {
      if (store.getters['beforeUrl'] || to.name !== 'login') {
        next(store.getters['beforeUrl'])
      } else {
        next('dashboard')
      }
    }
  } else {
    next()
  }
})
export default router
