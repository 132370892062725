export default {
    path:'/profile',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Ubah profile',
            component : () => import('@/views/Profile/index.vue')
        },
    ]
}

