export default {
    path:'/report',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Report',
            component : () => import('@/views/report/index.vue')
        },
    ]
}

