<template>
    <div class="text-xs">
        <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
        <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
            <div class="relative mx-auto w-auto max-w-full">
            <div class="bg-white w-full rounded-2xl p-8 overflow-auto max-h-screen">
                <div style="width:23rem">
                    <div class="flex justify-center text-left w-full">
                        <div class="text-left w-full">
                            <div class="w-full text-xs">
                                <ValidationObserver v-slot="{ invalid}" ref="form" >
                                <form @submit.prevent="gantiPassword()" class="w-full" >
                                
                                <ValidationProvider rules="required" v-slot="{ errors }" name="Password Lama" vid="old_password">
                                <iconInput v-model="data.old_password" :field="data.old_password" placeholder="Password Lama" type="password" />
                                <div class="mb-4">
                                    <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                                </div>

                                </ValidationProvider>
                                <ValidationProvider rules="required" v-slot="{ errors }" name="Password Baru" vid="password">
                               <iconInput v-model="data.password" :field="data.password" placeholder="Password Baru" type="password" />
                                <div class="mb-4">
                                    <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                                </div>

                                </ValidationProvider>
                            
                                <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" name="Password Konfirmasi" vid="confirmation_password">
                                <iconInput v-model="data.confirmation_password" :field="data.confirmation_password" placeholder="Konfirmasi Password" type="password" />
                                <div class="mb-4">
                                    <span class="text-red-600 text-xs">{{ errors[0] }}</span>

                                </div>

                                </ValidationProvider>

                                <div class="grid grid-cols-2 gap-4">
                                     <span
                                       @click="setDialogChangePassword(false)"
                                        :class="`w-full text-center py-2 rounded cursor-pointer
                                        bg-gray-200`"


                                    >Cancle</span>
                                    <button
                                        :disabled="invalid"
                                        type="submit"
                                        :class="`w-full text-center py-2 rounded
                                        text-white
                                        bg-blue-500
                                        hover:bg-blue-900
                                        disabled:opacity-50
                                        `"


                                    >Ganti Password</button>
                                </div>
                               
                            </form>
                            </ValidationObserver>
                    
                            </div>

                        </div>
                    </div>
    
                    <!-- buttons -->
                    <!-- <div class="buttons absolute top-0 right-0">
                    <div class="modal-close btn border border-gray-300 p-1 px-4 font-semibold cursor-pointer text-gray-500 ml-auto rounded" id="closemenu" @click="dialog_danger=false">x</div>
                    </div> -->
                </div>

            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import iconInput from '@/components/formcomponent/iconinput.vue'
import moment from 'moment'
export default {
     data() {
        return {
            
            data:{
                password:'',
                old_password:'',
                confirmation_password:''
            },
            default_url: process.env.VUE_APP_IMAGES
        }
    },
      components:{
        ValidationProvider,ValidationObserver,iconInput
    },

  methods: {
      ...mapActions({
          setDialogChangePassword:'data/setDialogChangePassword'
      }),
    gantiPassword() {
            this.axios.post('v1/auth/change-password',this.data,{ headers:{
            'Authorization': 'Bearer ' + this.token,
            }}).then(ress =>{
              this.data={
                password:'',
                old_password:'',
                confirmation_password:''
            }
            this.setDialogChangePassword(false)
            this.$snack.success({
                    text: 'Berhasil Ubah Password',

                })
            })
            .catch((err) => {
               
                if (err.response.data.message.length) {
                    console.log("test")
                }
                 this.$refs.form.setErrors(err.response.data.message)
            })
        }
  },
    computed:{
        ...mapGetters({
            token:'auth/token',
        })
    }

};
</script>

<style>

</style>
