export default {
    path:'/category-device',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'Category Device',
            component : () => import('@/views/category_device/index.vue')
        },
        {
            path:'add',
            name:'Category Device.tambah',
            component : () => import('@/views/category_device/create.vue')
        },
        {
            path:'edit/:id',
            name:'Category Device.ubah',
            component : () => import('@/views/category_device/edit.vue')
        },
      
       
    ]
}

