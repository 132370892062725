export default {
    path:'/users',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'pengguna',
            component : () => import('@/views/users/index.vue')
        },
        {
            path:'add',
            name:'pengguna.tambah',
            component : () => import('@/views/users/create.vue')
        },
        {
            path:'edit/:id',
            name:'pengguna.ubah',
            component : () => import('@/views/users/edit.vue')
        },
    ]
}

