<template>
    <div class="w-full p-4 px-8 bg-white border-b-1 sticky top-0 z-40 flex
     justify-between items-center border border-gray-100 border-b-none border-t-none boder-r-none
     ">
        <div class="text-left flex items-center">
            <unicon name="align-left" class="mr-4 cursor-pointer" fill="royalblue" @click="setSidebar(!sidebar)"></unicon>
            <div v-if="currentPath == '/dashboard'" class="flex items-center">
                <span class="font-semibold text-gray-400 mr-2 cursor-pointer" @click="setCustomer('')">Dashboard</span> 
                <div class="truncate mr-2 hidden md:block" style="max-width: 15rem !important;">

                    <span v-if="customer" class="font-semibold text-gray-400 mr-2 truncate w-20 md:w-40"> / </span> <span v-if="customer" class="font-semibold text-black">{{customer.name}}</span>
                </div>

            </div>
             <div v-else-if="currentPath == '/monitor' || currentPath == '/monitor/detail'" class="flex items-center">
                <span class="font-semibold text-gray-400 mr-2 cursor-pointer" @click="setCustomer('')">Monitor</span>
                <div class="truncate mr-2" style="max-width: 15rem !important;">
                    <span v-if="customer" class="font-semibold text-gray-400 mr-2 truncate"> / </span> 
                    <span v-if="customer" class="font-semibold" :class="`${currentPath == '/monitor' ? ' text-black' : ' text-gray-400 cursor-pointer'} `" @click="currentPath == '/monitor/detail' ? setCurrentDevice('') : ''">{{customer.name}} </span>
                </div>
                <div>
                 <span v-if="currentPath == '/monitor/detail'" class="font-semibold text-gray-400 mr-2 truncate"> / </span> 
                    <span v-if="currentPath == '/monitor/detail'" class="font-semibold text-black">Detail</span>    
                </div> 
            </div>

            <div v-else class="flex items-center">
                <span class="font-semibold text-gray-400 mr-2 cursor-pointer" @click="$router.go(-1)">{{firtsToUpper(menu[0])}}</span>
                 <div class="truncate mr-2" style="max-width: 15rem !important;" v-if="menu.length >= 2">

                    <span class="font-semibold text-gray-400 mr-2 truncate w-40"> / </span> <span class="font-semibold text-black">{{firtsToUpper(menu[1])}}</span>
                </div>
            </div>
           <!-- {{detail ? detail.company_name : 'PT Nusa Network Prakarsa'}} -->
        </div>
        <div class="text-xs flex items-center">
            <div class="mr-4 border-r border-gray-200 py-2 px-4 group inline-block relative cursor-pointer" v-click-outside="closeDialogNotif">
                <div class="relative" @click="openDialogNotif()">
                    <unicon name="bell" width="20px" fill="#94A3B8" ></unicon>
                    <div v-if="total_read > 0" class="absolute -top-1 -right-1 w-3.5 h-3.5 bg-red-500 text-white rounded-full flex items-center content-center text-center">
                        <span class="w-full" style="font-size:0.5rem">{{total_read}}</span>
                    </div>
                    <div class="shadow transform scale-100 text-black absolute
                    transition duration-150 ease-in-out origin-top w-full
                    bg-white rounded-md md:shadow flex-none py-4 -right-24 md:right-0 
                    " v-if="dialog_notif" style="min-width:320px;" >   
                        <div class="grid grid-cols-12 gap-4 pb-2 border-b border-gray-200 px-4">
                            <span class="font-bold text-sm">Notifikasi</span>
                        </div>
                        <div class="px-4 py-2 overflow-x-auto" style="height:400px;"  @scroll="handleScroll" v-if="notifikasi.length > 0">
                           <div class="grid grid-cols-12 gap-4 mb-2 border-b border-gray-200 pb-2" v-for="item in notifikasi" :key="item.id">
                               <div class="col-span-2 ">
                                   <div class="rounded-lg bg-red-100 py-1.5  flex flex-warp items-center content-center justify-center">
                                        <unicon name="exclamation-triangle" width="18px" fill="#EF4444" ></unicon>
                                   </div>
                               </div>
                               <div class="col-span-9">
                                   <div class="text-gray-500">
                                     <p class="font-bold text-black text">{{item.judul}}</p>
                                     <span>{{item.tgl}}</span>
                                     <p>{{item.message}}</p>
                                   </div>   
                               </div>
                           </div>
                        </div>
                        <div v-else class="text-center w-full text-gray-500 pt-4">
                            Tidak ada notifikasi
                        </div>
                    </div>
                </div>
            </div>
           <div class="group inline-block relative cursor-pointer"  v-click-outside="closeDialog">
                <div class="flex flex-wrap content-center items-center justify-end" >
                    <button class="flex flex-wrap content-center items-center justify-end mr-2"  @click="openDialog()">
                        <div class="h-8 w-8 rounded-full border border-gray-200 mr-2">
                            <img class="h-8 w-8 object-cover  rounded-full" :src="user.foto != null ? default_url+user.foto : img"/>
                        </div>
                    
                        <div class="text-left w-20">
                            <p class="text-blue-500 text-sm truncate">{{user.name}}</p> 
                            <p class="text-xs truncate">{{user.role.name}}</p>
                        </div>
                    </button>
                </div>
                <div
                    class="shadow transform scale-100 text-black absolute
                transition duration-150 ease-in-out origin-top w-full
                bg-white rounded-md md:shadow flex-none py-4 right-0
                " v-if="dialog" style="min-width:250px"
                >   
                    <div class="grid grid-cols-12 gap-4 pb-2 border-b border-gray-200 px-4">
                         <div class="col-span-2 h-8 w-8 rounded-full border border-gray-200">
                            <img class="h-8 w-8 object-cover  rounded-full" :src="user.foto != null ? default_url+user.foto : img"/>
                        </div>
                    
                        <div class="col-span-10 text-left pl-2">
                            <p class="text-blue-500 text-sm truncate">{{user.name}}</p> 
                            <p class="text-xs truncate">{{user.role.name}}</p>
                        </div>
                    </div>
                    <div>
                        <div class=" px-4 py-3 hover:bg-gray-200 flex items-center" @click="$router.push('/profile')">
                             <unicon name="user" class="mr-2" width="16px" ></unicon>
                            Ubah Profile
                        </div>

                        <div class="px-4 py-3 hover:bg-gray-200 border-b flex items-center border-gray-200" @click="setDialogChangePassword(true)" v-show="user.name !== 'Demo'">
                             <unicon name="key-skeleton" class="mr-2" width="16px" ></unicon>
                            Ubah Kata Sandi
                        </div>
                    </div>
                    <div class="mt-4 w-full px-4 rounded-xl">
                        <div class="py-2 w-full bg-blue-500 rounded text-center text-white cursor-pointer" @click="logout()">Keluar</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
    data() {
        return {
            dialog:false,
            dialog_notif:false,
            default_url: process.env.VUE_APP_IMAGES,
            menu:[],
            notifikasi:[],
            total_read:0,
            total_notif:10,
            total_offset:0,
            loading_api:false
        }
    },
    methods: {
        ...mapActions({
            setUser:'auth/setUser',
            setDevice:'auth/setDevice',
            setSidebar:'sidebar/setSidebar',
            setCustomer:'device/setCustomer',
            setCurrentDevice:'device/setCurrentDevice',
            setDialogChangePassword:'data/setDialogChangePassword'
        }),
        openDialog() {
            this.dialog = !this.dialog
            this.dialog_notif = false
        },
        closeDialogNotif() {
            this.dialog_notif = false
        },
        openDialogNotif() {
            this.dialog_notif = !this.dialog_notif
            this.dialog = false
            this.readNotif()
            
        },
        closeDialog() {
            this.dialog = false
        },
        handleScroll (event) {
            if (this.loading_api == false) {
                this.getNotif()
            }
        },
        async readNotif() {
            if (this.total_read > 0) {
                await this.axios.post(`v2/notification?`,{},{ headers:{
                'Authorization': 'Bearer ' + this.token,
                }})
                this.total_read = 0
            }

                
        },
        parseDate(date) {
             // start time and end time
            let startTime = moment(date);
            let endTime = moment();

                // calculate total duration
            let duration = moment.duration(endTime.diff(startTime));

                // duration in hours
            let hours = parseInt(duration.asHours());
            let days = parseInt(duration.asDays());
            let minutes = parseInt(duration.asMinutes());
            let status = 'Sekarang'
            if (minutes > 0 && hours <= 0) {
                status = `${minutes} menit yang lalu`
            } else if(hours > 0 && minutes > 0 ){
                   let jam = `${hours > 0 && hours < 24 ? hours + ' jam' :''}`
                let hari = `${days > 0 ? days+ ' hari' :''}`
                status = `${hari} ${jam} yang lalu`
            }
            return status
             
        },
        async getNotif(){
            if (this.total_notif != this.total_offset) {
                this.loading_api = true
                 await this.axios.get(`v2/notification?offset=${this.total_offset}`,{ headers:{
                    'Authorization': 'Bearer ' + this.token,
                    }})
                    .then((ress) => {
                        this.total_notif = ress.data.data.count
                        let next = ress.data.data.count - this.total_offset
                        if (next > 0) {
                            if (next > 10) {
                                this.total_offset = this.total_offset + 10
                            }   else {
                                this.total_offset = this.total_offset + next
                            }
                        } else {
                            this.total_offset = 0
                        }
                       
                        this.total_read = ress.data.total_not_read
                        ress.data.data.rows.forEach(element => {
                            let new_record = {...element}
                            new_record.tgl = this.parseDate(new_record.createdAt)
                            this.notifikasi.push(new_record)
                        });
                         
                    })
                this.loading_api = false
            }
           
        },
        logout() {
            
            this.axios.post('v1/auth/logout',{},{ headers:{
            'Authorization': 'Bearer ' + this.token,
            }}).then((ress) => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.setUser({user:null,token:null})
                this.$router.push('/login')
            })
        },

        firtsToUpper(world){
            if (world) {
                return world.slice(0,1).toUpperCase() + world.slice(1);
            }

            return ''
            
        },
        async getDevice() {
            if (this.user.group_id || this.user.customer_id || this.user.reseller_id) {
                await this.axios.get('v1/device?status=1',{ headers:{
                    'Authorization': 'Bearer ' + this.token,
                    }})
                .then((ress) => {
                    this.setDevice(ress.data.rows)  
                })
            }
          
        },
        addNotif(data) {
            console.log("nananana", data)
            let find_device = this.device.find(x => x.id == data.device_id)
            if (find_device || (!this.user.group_id && !this.user.customer_id && !this.user.reseller_id )) {
                this.notifikasi.unshift(data)
                this.total_read = this.total_read + 1
            }
        }
    },
    mounted(){
        
        let id_message = ''
        if (this.user.customer_id) {
            id_message = this.user.customer_id
        }else if(this.user.reseller_id) {
            id_message = this.user.reseller_id
        } else {
            id_message = 'dev'
        }
        
        this.sockets.subscribe('message-' +id_message, (data) => {
            this.addNotif(data)
           
        });

        this.sockets.subscribe('message-siaga', (data) => {
            this.addNotif(data)
           
        });
    },
    components:{
        ValidationProvider,ValidationObserver
    },

    created(){
        this.getNotif()
        this.getDevice()
        //  window.addEventListener('scroll', this.handleScroll);
    },
     destroyed () {
    // window.removeEventListener('scroll', this.handleScroll);
  },

    watch: {
        currentPath: function (newVal,oldVal) {
            let url = this.$route.name.split(".");
            this.menu = url
        }
    },

    computed:{
        ...mapGetters({
            token:'auth/token',
            detail:'auth/detail',
            user:'auth/user',
            device:'auth/device',
            sidebar:'sidebar/sidebar',
            customer:'device/customer',
            currentPath:'data/currentPath'
        })
    }
}
</script>