<template>
    <div class="text-xs">
        <div class="fixed inset-0 z-50 opacity-50 bg-black"></div>
        <div class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50">
            <div class="relative mx-auto w-auto max-w-full">
            <div class="bg-white w-full rounded-2xl px-4 py-8 overflow-auto max-h-screen relative">
                <div style="width:23rem">
                    <div class="flex flex-warp justify-center  text-center">
                        <div class="">
                            
                            <div class="flex flex-warp justify-center items-center ">
                                <unicon name="exclamation-triangle" fill="#EF4444" width="44.26px" height="40.14px" ></unicon>
                            </div>
                            <div class="font-bold mt-4"> 
                                <p class="text-xl">{{data.name}}</p>
                                <p class="mt-2 text-base text-yellow-500" v-if="data.siaga_level == 2">Ketinggian Air {{parseFloat(data.real_ketinggian * 100).toFixed(2)}} cm</p>
                                <p class="mt-2 text-base text-blue-500" v-else-if="data.siaga_level == 3">Ketinggian Air {{parseFloat(data.real_ketinggian * 100).toFixed(2)}} cm</p>
                                <p class="mt-2 text-base text-blue-500" v-else-if="data.siaga_level == 4">Ketinggian Air {{parseFloat(data.real_ketinggian * 100).toFixed(2)}} cm</p>
                                <p class="mt-2 text-base text-red-500" v-else>Ketinggian Air {{parseFloat(data.real_ketinggian * 100).toFixed(2)}} cm</p>

                            </div>
                    
                           
                            <div class="mt-2 text-base text-gray-400" v-html="data.date_parse"></div>

                            <div class="modal-close absolute top-2 right-4
                            cursor-pointer text-gray-500 ml-auto rounded" id="closemenu" @click="stop()">
                            x</div>

                        </div>
                    </div>
    
                    <!-- buttons -->
                    <!-- <div class="buttons absolute top-0 right-0">
                    <div class="modal-close btn border border-gray-300 p-1 px-4 font-semibold cursor-pointer text-gray-500 ml-auto rounded" id="closemenu" @click="dialog_danger=false">x</div>
                    </div> -->
                </div>

            </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
  props:['data'],
  methods: {
      stop(){
          this.$emit('stop')
      },
         parseDate(date){
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
  }

};
</script>

<style>

</style>
