export default {
    path:'/device',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'perangkat',
            component : () => import('@/views/device/index.vue')
        },
        // {
        //     path:'add',
        //     name:'Tambah Perangkat',
        //     component : () => import('@/views/device/create.vue')
        // },
        {
            path:'edit/:id',
            name:'perangkat.ubah',
            component : () => import('@/views/device/edit.vue')
        },
        {
            path:'kalibrasi/:id',
            name:'perangkat.kalibrasi',
            component : () => import('@/views/device/kalibrasi.vue')
        },
        {
            path:'adjustment/:id',
            name:'perangkat.adjustment',
            component : () => import('@/views/device/adjustment.vue')
        },
       
    ]
}

