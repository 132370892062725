export default {
    path:'/menu',
    component:() => import('../views/index.vue'),
    meta:{auth:true},
    children:[
        {
            path:'',
            name:'menu',
            component : () => import('@/views/menu/index.vue')
        },
        {
            path:'add',
            name:'menu.tambah',
            component : () => import('@/views/menu/create.vue')
        },
        {
            path:'edit/:id',
            name:'menu.ubah',
            component : () => import('@/views/menu/edit.vue')
        },
     
    ]
}

