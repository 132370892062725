export default {
    namespaced: true,
    state: {
      image:{
        level:null,
        vsol:null,
        hum:null,
        kpa:null,
      },
      status:1
    },
    mutations: {
      image:(state,payload) => {
         state.image[payload.img] = payload.url
      },
      status:(state,payload) => {
          state.status = payload
      }
    },
    actions: {
      setImage: ({ commit }, payload) => {
        commit('image', payload)
      },
      setStatus: ({ commit }, payload) => {
        commit('status', payload)
      },
    },
    getters: {
      imagelevel: state => state.image.level,
      imagevsol: state => state.image.vsol,
      imagehum: state => state.image.hum,
      imagekpa: state => state.image.kpa,
      statusImage: state => state.status,
    }
}
  