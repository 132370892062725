<template>
  <div style="background-color:#F5F6F8 !important">
    <div id="app" class="grid grid-cols-12" >
    
      <aside v-if="user && sidebar" class="h-screen fixed md:sticky top-0 col-span-12 md:col-span-2" style="z-index:100000 !important">
            <SidebarMenu v-if="user && sidebar" class="md:w-full" />
      </aside>

      <div class="w-full col-span-12" :class="`${sidebar ? 'md:col-span-10' : ''}`">
          <HeaderMenu v-if="user" />
          <transition name="fade">
              <router-view/>
          </transition>
      </div>
            
    </div>
    <dangerAlert :data="data_danger" v-on:stop="stop()" v-if="dialog_danger" style="z-index:111000 !important"/>
        <audio style="display: none;" id="dangerAlert">
            <source :src="require('./assets/emergency.mp3')" type="audio/mpeg">
        </audio>
      <changePassword style="z-index:110000 !important" v-if="dialogChangePassword"/>
  </div>
  
</template>
<style>

</style>
<script>
import {mapGetters} from 'vuex'
import SidebarMenu from '@/components/layouts/SidebarMenu.vue'
import HeaderMenu from '@/components/layouts/HeaderMenu.vue'
import { useFavicon } from '@vueuse/core'
import dangerAlert from '@/components/dangerAlert.vue'
import changePassword from '@/components/changePassword.vue'
const icon = useFavicon()
export default {
  data() {
    return {
      test:'test',
      default_url:process.env.VUE_APP_IMAGES,
      first_danger:true,
      mute: true,
      audio2: null,
      data_danger:null,
      dialog_danger:false,


    }
  },
   sockets: {
    connect: function () {
        console.log('socket connected')
    },
    disconnect() {
      console.log("Socket Galih 断开");
  },
    customEmit: function (data) {
        console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  mounted(){
    this.sockets.subscribe('dangeralert', (data) => {
      console.log(data)
      console.log(this.$route.path)
      if(this.user && this.$route.path !=='/monitor/detail')
      if(data) {
        this.data_danger = data
        this.dialog_danger = true
        this.play()
      }
    });
    this.audio2 = document.getElementById('dangerAlert');

  },
  components:{
    SidebarMenu,HeaderMenu,dangerAlert,changePassword
  },
  computed:{
    ...mapGetters({
      user:'auth/user',
      detail:'auth/detail',
      sidebar:'sidebar/sidebar',
      dialogChangePassword:'data/dialogChangePassword'
    })
  },
  methods:{
    
      play(){
          
          this.audio2.play();
          this.audio2.loop = true;
          this.mute = false;
          
      },
      stop() {
          this.audio2.pause();
          this.mute = true;
          this.dialog_danger = false
        
      }
  },

  watch:{
    user: function (newQuestion, oldQuestion) {
        // if (this.detail) {
        // icon.value = this.default_url + this.detail.foto
        //   } else {
            icon.value = this.default_url + 'favicon.ico'
        // }
    
    }
  },
  created() {
    
  },
  
}
</script>
